import React from "react"
import ReactMaterialComponentBase from "../../Shared/ReactMaterialComponentBase"
import { ListRecipe as ReactListRecipe } from "../React/ListRecipe"

export default class ListRecipe extends ReactMaterialComponentBase {
  static get observedAttributes() {
    return ["target"]
  }

  constructor() {
    super()

    /**
     * @type {Array<import("../Types").Recipe>}
     */
    this.recipes = []

    this.jsxRootComponent = () => (
      <ReactListRecipe recipes={this.recipes} updateRecipe={(recipe) => this.updateRecipe(recipe)} />
    )

    this.target = this.getAttribute("target")

    this.getRecipes()
  }

  async attributeChangedCallback(name, oldValue, newValue) {
    if (name !== "target") {
      return
    }

    if (!newValue) {
      return
    }

    this.target = newValue

    await this.getRecipes()
  }

  async getRecipes() {
    if (!this.target) {
      return
    }

    try {
      const token = await window.authorise()

      const getRecipesResponse = await fetch(this.target, {
        method: "GET",
        headers: { Authorization: `Bearer ${token}` },
      })

      const recipes = /** @type {Array<import("../Types").Recipe>} */ (await getRecipesResponse.json())

      this.recipes = recipes

      this.render()
    } catch (error) {
      this.onError && this.onError(error)
    }
  }

  async updateRecipe(recipe) {
    try {
      const token = await window.authorise()

      await fetch(`${this.target}/${recipe.id}`, {
        method: "PUT",
        headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/json" },
        body: JSON.stringify(recipe),
      })

      this.getRecipes()
    } catch (error) {
      this.onError && this.onError(error)
    }
  }
}

customElements.define("list-recipe", ListRecipe)
