import React, { useContext, useState } from "react"
import { Grid } from "@material-ui/core"
import { RecipeCard } from "./RecipeCard"
import { RecipeCardDialog } from "./RecipeCardDialog"
import { ShadowContext } from "../../Shared/ShadowContext"

/**
 * @param {{ recipes: Array<import("../Types").Recipe>; recipeSelected?: (recipe: import("../Types").Recipe) => void; updateRecipe?: (recipe: import("../Types").Recipe) => void }} props
 * @returns {JSX.Element}
 */
export const ListRecipe = (props) => {
  const { recipes } = props

  const [recipe, setRecipe] = useState(null)
  const [open, setOpen] = useState(false)

  const { shadowRoot } = useContext(ShadowContext)

  return (
    <>
      <Grid id="container" container spacing={2}>
        {recipes.map((recipe, index) => {
          const { recipeSelected, updateRecipe } = props

          return (
            <Grid key={index} item xs={12} sm={6} md={4} lg={3} xl={2}>
              <RecipeCard
                recipe={recipe}
                recipeSelected={(recipe) => recipeSelected && recipeSelected(recipe)}
                updateRecipe={(recipe) => updateRecipe && updateRecipe(recipe)}
                expandClick={(recipe) => {
                  setRecipe(recipe)
                  setOpen(true)
                }}
              />
            </Grid>
          )
        })}
      </Grid>
      {recipe ? (
        <RecipeCardDialog
          recipe={recipe}
          open={open}
          onClose={() => {
            setRecipe(null)
            setOpen(false)
          }}
          shadowRoot={shadowRoot}
        />
      ) : null}
    </>
  )
}
